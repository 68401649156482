<template>
  <div class="q-pa-md">
    <q-card>
      <q-card-section class="row border-bottom items-center full-width q-py-xs q-pl-none">
        <q-legend
          :label="$t('Roles')"
          text-class="text-h6"
        />

        <q-space />

        <q-btn
          color="light-blue-9"
          text-color="white"
          icon="add"
          size="sm"
          no-caps
          unelevated
          @click="create"
        />
      </q-card-section>

      <q-card-section>
        <role-row ref="roleRow" :filter="filter" />
      </q-card-section>
    </q-card>

    <role-modal ref="roleModal" @submit="handleSubmit" />
  </div>
</template>

<script>
// Components
import RoleModal from '../../components/modals/RoleModal.vue'

export default {
  name: 'Roles',
  components: {
    RoleModal
  },
  data () {
    return {
      filter: [
        { type: 'isnull', field: 'parent' }
      ]
    }
  },
  methods: {
    create () {
      this.$refs.roleModal.open({})
    },
    handleSubmit (role) {
      if (this.$refs.roleRow) {
        this.$refs.roleRow.handleSubmit(role)
      }
    }
  }
}
</script>
